import * as React from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { useRouter } from "next/router";
import Utils from "../../core/Utils";
import { useApp } from "../../ctx/AppProvider";
import { TOAST_STATUSES, useToast } from "../../ctx/ToastProvider";
import errors from "../../data/i18n/en.json";
import useDOMEvent from "../../hooks/useDOMEvent";
import useQuery from "../../hooks/useQuery";
import useWindowEvent from "../../hooks/useWindowEvent";
import { GetTradesDocument } from "../../graphql/gql-types";
import Alert from "../Alert/Alert";
import BalanceCard from "./BalanceCard";
import { AMOUNT_TYPES } from "../quote-form/QuoteForm";

import type {
  Client,
  Balance,
  GetTradesQuery,
  GetTradesQueryVariables,
  Trade,
} from "../../graphql/gql-types";
import type { PartialBy } from "../../lib/UtilityTypes";

export interface IBalancesProps {
  items: PartialBy<Balance, "funds_held" | "uninstructed">[];
}

const Balances: React.FC<IBalancesProps> = ({ items }) => {
  const [activeClient] = useApp<Client>((store) => store.activeClient);
  const [, setTradeHistoryItems] = useApp<Trade[]>(
    (store) => store.tradeHistoryItems,
  );
  const toast = useToast();
  const router = useRouter();

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [totalBalance, setTotalBalance] = React.useState<number>(0);
  const [toggleScroll, setToggleScroll] = React.useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = React.useState<string>("left");
  const [selectedBalance, setSelectedBalance] =
    React.useState<Balance | null>();
  const shouldFetchTrades =
    Utils.isNotEmpty(selectedBalance) && selectedBalance.uninstructed > 0;

  const onResize = () => {
    if (containerRef.current) {
      setToggleScroll(Utils.hasHorizontalScrollBar(containerRef.current));
    }
  };

  useQuery<
    GetTradesQuery["getTrades"],
    GetTradesQuery,
    GetTradesQueryVariables
  >(
    shouldFetchTrades ? GetTradesDocument : null,
    {
      client_ref: activeClient?.cli_reference,
      limit: 100,
      currency: selectedBalance?.currency,
      filter: null,
      // direction: TradeDirection.BUY, // Todo: (PAY-127) Passed as data to an argument that is not part of the query! See src/graphql/trades/trades.graphql
    },
    [],
    ({ trades }) => {
      const { currency, balance, funds_held, uninstructed } = selectedBalance;
      const filteredTrades: Trade[] = Utils.getAllSettledTrades(
        trades,
        currency,
      );
      setTradeHistoryItems({ tradeHistoryItems: filteredTrades });

      router.push({
        pathname: "/source-funds",
        query: {
          balance_currency: currency,
          balance_amount: balance,
          fundsHeld: funds_held,
          uninstructed,
        },
      });
    },
    () => {
      toast.notify({
        message: errors.tradeFetch.message,
        status: TOAST_STATUSES.CRITICAL,
      });
      setSelectedBalance(null);
    },
  );

  React.useEffect(() => {
    const currentBalance = items.reduce(
      (acc, curr) => (acc += curr.balance),
      0,
    );

    setTotalBalance(parseFloat(currentBalance.toFixed(2)));

    onResize();
  }, [items]);

  useWindowEvent("resize", onResize);

  useDOMEvent(
    "#carousel",
    "wheel",
    (event) => {
      event.preventDefault();

      let magnitude;
      const [x, y] = [event.deltaX, event.deltaY];

      if (x === 0) {
        magnitude = y > 0 ? -50 : 50;
      } else {
        magnitude = x;
      }

      containerRef.current.scrollBy({
        left: magnitude,
      });
    },
    { passive: false },
  );

  // This effect listening for a balance which has not trades
  React.useEffect(() => {
    if (
      Utils.isNotEmpty(selectedBalance) &&
      selectedBalance.uninstructed === 0
    ) {
      const { currency, balance } = selectedBalance;
      router.push({
        pathname: "/transfer",
        query: {
          balance_currency: currency,
          balance_amount: balance,
          amountType: AMOUNT_TYPES.SELL,
        },
      });
    }
  }, [selectedBalance]);

  return (
    <div className="bg-white px-2 md:px-6 pt-8">
      <h2 className="text-teal-700 mb-2 sm:mb-4 md:mb-6 lg:mb-8 text-base sm:text-lg md:text-xl lg:text-2xl">
        Balances
      </h2>

      <div className="flex items-center relative">
        {toggleScroll && scrollPosition !== "left" && (
          <div
            className="cursor-pointer absolute flex h-full items-center"
            onClick={() => {
              containerRef.current.scrollLeft -= 250;
            }}
          >
            <ChevronLeftIcon
              width="20"
              className="hover:text-navy-700 bg-white h-full"
            />
          </div>
        )}
        <div
          id="carousel"
          className="flex items-center flex-nowrap space-x-2 w-full scroll-smooth overflow-x-auto scrollbar-hide"
          ref={containerRef}
          onScroll={() => {
            const { scrollLeft, scrollWidth, offsetWidth } =
              containerRef.current;

            if (scrollLeft === 0) {
              setScrollPosition("left");
            } else if (scrollLeft === scrollWidth - offsetWidth) {
              setScrollPosition("right");
            } else {
              setScrollPosition("");
            }
          }}
        >
          {totalBalance > 0 && (
            <div data-testid="alert-card" className="w-64 flex-shrink-0">
              <Alert
                status="primary"
                icon={InformationCircleIcon}
                text="You have a balance(s) that requires action"
              />
            </div>
          )}
          {items.map(({ balance, currency, funds_held, uninstructed }) => {
            const isBalanceEmpty = balance === 0;
            return (
              <BalanceCard
                onClick={() =>
                  setSelectedBalance({
                    balance,
                    currency,
                    funds_held,
                    uninstructed,
                  })
                }
                key={currency}
                isisLoading={Utils.isNotEmpty(selectedBalance)}
                isDisabled={isBalanceEmpty}
                balance={balance}
                currency={currency}
              />
            );
          })}
        </div>
        {toggleScroll && scrollPosition !== "right" && (
          <div
            className="cursor-pointer absolute flex h-full items-center right-0"
            onClick={() => {
              containerRef.current.scrollLeft += 250;
            }}
          >
            <ChevronRightIcon
              width="20"
              className="hover:text-navy-700 bg-white h-full"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Balances;
